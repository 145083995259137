<template>
 <div class="flex h-full -mt-12">
  <div
   class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
  >
   <div class="mx-auto w-full max-w-lg">
    <div>
     <img class="h-20 w-auto bg-gray-200 p-2 rounded-md" :src="logo" alt="Encom" />
     <h2 v-if="checkToken" class="mt-3 text-2xl font-bold leading-9 tracking-tight text-gray-900">
      {{ $t("passwordReset") }}
     </h2>

     <p class="mt-2 text-sm leading-6 text-gray-700">
      {{ $t("newPasswordCondition") }}
     </p>
     <ul v-for="condition in conditionList" class="list-disc list-inside text-sm text-gray-500">
      <li>{{ $t(condition.name) }}</li>
     </ul>
    </div>

    <div class="mt-10">
     <div>
      <form @submit.prevent="onSubmit" method="POST" class="space-y-6">
       <div>
        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("emailAddress") }}
        </label>
        <div class="relative rounded-md shadow-sm">
         <input
          v-model="email"
          id="email"
          name="email"
          type="email"
          autocomplete="email"
          required
          :class="{
           'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
            errors.bool && errors.lists.includes('email'),
           'text-gray-900 ring-gray-300 placeholder:text-gray-300': !errors.bool,
          }"
          class="block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
         />
         <div
          v-show="errors.bool && errors.lists.includes('email')"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="h-5 w-5 text-red-500"
          >
           <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
           />
          </svg>
         </div>
        </div>
        <p
         v-show="errors.bool && errors.lists.includes('email')"
         class="ml-1 mt-1 text-sm text-red-600"
         id="email-error"
        >
         Not a valid email address.
        </p>
       </div>
       <div>
        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("password") }}
        </label>
        <div class="relative rounded-md shadow-sm">
         <input
          v-model="password"
          id="password"
          name="password"
          type="password"
          autocomplete="new-password"
          required
          :class="{
           'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
            errors.bool,
           'text-gray-900 ring-gray-300 placeholder:text-gray-300': !errors.bool,
          }"
          class="block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
         />
         <div
          v-show="errors.bool"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="h-5 w-5 text-red-500"
          >
           <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
           />
          </svg>
         </div>
        </div>
        <p v-if="errors.bool" class="ml-1 mt-1 text-sm text-red-600" id="email-error">
         {{ $t(errors.lists[0]) }}
        </p>
       </div>
       <div>
        <label for="confirm-password" class="block text-sm font-medium leading-6 text-gray-900">
         {{ $t("confirmPassword") }}
        </label>
        <div class="relative rounded-md shadow-sm">
         <input
          v-model="confirmPassword"
          id="password"
          name="password"
          type="password"
          autocomplete="new-password"
          required
          :class="{
           'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
            errors.bool,
           'text-gray-900 ring-gray-300 placeholder:text-gray-300': !errors.bool,
          }"
          class="block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
         />
         <div
          v-show="errors.bool"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="h-5 w-5 text-red-500"
          >
           <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
           />
          </svg>
         </div>
        </div>
        <p v-if="errors.bool" class="ml-1 mt-1 text-sm text-red-600" id="email-error">
         {{ $t(errors.lists[0]) }}
        </p>
       </div>

       <div class="flex items-center justify-between">
        <div class="text-sm leading-6">
         <a href="/login" class="font-semibold encom_primary_text">
          {{ $t("login.backToLogin") }}
         </a>
        </div>
       </div>
       <div>
        <button
         type="submit"
         class="flex w-full justify-center rounded-md encom_primary encom_primary_hover px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
        >
         {{ $t("ticket.send") }}
        </button>
       </div>
      </form>
     </div>
    </div>
   </div>
  </div>
  <div class="relative hidden w-0 flex-1 lg:block">
   <img class="absolute inset-0 h-full w-full object-cover" :src="background" alt="" />
  </div>
 </div>
 <!--  <div class="login_page">
  <div class="login_card">
   <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 card"
   >
    <div class="max-w-md w-full space-y-8">
     <div class="card-img-top">
      <img class="mx-auto h-auto w-auto" :src="logo" alt="Workflow" />
      <h2 v-if="checkToken" class="mt-6 text-center text-3xl font-extrabold text-gray-900">
       Reset password
      </h2>
      <h2
       v-if="!checkToken"
       class="mt-4 mb-4 pb-5 text-center text-3xl font-extrabold text-gray-900"
      >
       Invalid Token
      </h2>

      <router-link
       v-if="!checkToken"
       to="/login"
       class="rounded body__table--header group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none encom_ring_color"
      >
       <span class="absolute left-0 inset-y-0 flex items-center pl-3"> </span>
       Login page
      </router-link>
     </div>
     <div class="mt-4 footer-text pl-3" v-if="checkToken">
      <p class="text-base text-gray-600">
       {{ $t("passwordResetMustContain12Characters") }}.<br />

       {{ $t("passwordResetMustContainOneUppercase") }}.<br />

       {{ $t("passwordResetMustContainOneSpecialCharacter") }}.<br />
       {{ $t("passwordResetMustContainOneNumber") }}.
      </p>
     </div>
     <div v-if="checkToken" class="form_card">
      <form class="space-y-6" @submit.prevent="onSubmit" method="POST">
       <input type="hidden" name="remember" value="true" />
       <div class="shadow-sm -space-y-px">
        <div>
         <label for="email-address" class="sr-only email_label">Email address</label>
         <input
          id="email-address"
          v-model="email"
          name="email"
          type="email"
          autocomplete="email"
          required=""
          class="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          placeholder="Email address"
         />
        </div>
        <div>
         <label for="password" class="sr-only">Password</label>
         <input
          id="password"
          v-model="password"
          name="password"
          type="password"
          required=""
          class="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:encom_border_input focus:z-10 sm:text-sm"
          placeholder="Password"
         />
        </div>
        <div>
         <label for="password" class="sr-only">Confirm Password</label>
         <input
          id="confirmPassword"
          v-model="confirmPassword"
          name="confirmPassword"
          type="password"
          required=""
          class="mt-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:encom_border_input focus:z-10 sm:text-sm"
          placeholder="Confirm password"
         />
        </div>
       </div>

       <div class="flex items-center justify-between">
        <div class="text-sm">
         <router-link to="/login" class="font-medium encom_ouvert_ticket_text">
          Login page
         </router-link>
        </div>
       </div>

       <div>
        <button
         type="submit"
         class="rounded body__table--header group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none encom_ring_color"
        >
         <span class="absolute left-0 inset-y-0 flex items-center pl-3">
          <LockClosedIcon
           class="h-5 w-5 encom_primary_text group-hover:encom_secondary_text"
           aria-hidden="true"
          />
         </span>
         Send
        </button>
       </div>
      </form>
     </div>
    </div>
   </div>
  </div>
 </div> -->
</template>

<script>
import axios from "axios";
//import Card from "../components/Card.vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

const logo = localStorage.getItem("logo");
const background = localStorage.getItem("background");

const conditionList = [
 {
  name: "passwordResetMustContain12Characters",
 },
 {
  name: "passwordResetMustContainOneUppercase",
 },
 {
  name: "passwordResetMustContainOneSpecialCharacter",
 },
 {
  name: "passwordResetMustContainOneNumber",
 },
];
export default {
 components: { LockClosedIcon },
 name: "ResetPassword",
 props: ["passwordToken"],
 data() {
  return {
   email: "",
   password: "",
   confirmPassword: "",
   conditionList,
   checkUpperCase: false,
   checkToken: false,
   logo,
   background,
   errors: [{ bool: false, lists: [] }],
  };
 },
 methods: {
  async onSubmit() {
   let isNumeric = false;
   let isUpper = false;
   let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
   this.errors.bool = false;
   this.errors.lists = [];

   const data = {
    email: this.email,
    password: this.password,
    password_confirmation: this.confirmPassword,
    token: `${this.passwordToken}`,
   };
   let i = 0;
   let character = "";
   while (i < this.password.length) {
    character = this.password.charAt(i);
    if (!isNaN(character)) {
     isNumeric = true;
    } else {
     if (character == character.toUpperCase() && !format.test(character)) {
      isUpper = true;
     }
    }

    i++;
   }

   if (this.password !== this.confirmPassword) {
    this.errors.bool = true;
    this.errors.lists.push("passwordMustMatch");
   } else if (this.password.length < 12) {
    this.errors.bool = true;
    this.errors.lists.push("passwordLength");
   } else {
    if (format.test(this.password) && isNumeric && isUpper) {
     axios
      .post(`${this.$cookie.getCookie("API")}/api/v1/resetPassword`, data)
      .then((res) => {
       axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
       localStorage.setItem("token", res.data.token);
       this.$store.dispatch("token", res.data.token);
       if (
        res.data.user.accounts.includes("ADMIN") ||
        res.data.user.activeAccount.includes("ADMIN")
       ) {
        localStorage.setItem("adminToken", res.data.token);
        this.$store.dispatch("adminToken", res.data.token);
       }
       localStorage.setItem("avatar", res.data.user.avatar);
       if (!res.data.user.activeAccount) {
        localStorage.setItem("account", res.data.user.accounts[0]);
        this.$store.dispatch("account", res.data.user.accounts[0]);
        this.account = res.data.user.accounts[0];
       } else {
        localStorage.setItem("account", res.data.user.activeAccount);
        this.$store.dispatch("account", res.data.user.activeAccount);
        this.account = res.data.user.activeAccount;
       }
       localStorage.setItem("showBanner", true);
       this.$store.dispatch("user", res.data.user);
       localStorage.setItem("user", JSON.stringify(res.data.user));
       const initials = res.data.user.name
        .split(" ")
        .map((name) => name.charAt(0))
        .join("");
       localStorage.setItem("initials", initials);
       this.$store.dispatch("userAvatar", res.data.user.avatar);
       if (this.account !== "ADMIN") {
        this.getPbxHosts(
         res.data.token,
         res.data.user.activeAccount ? res.data.user.activeAccount : res.data.user.accounts[0]
        );
       }
       this.$forceUpdate();
      })
      .then((res) => {
       this.errorLogin = false;
       this.$router.push(this.$route.query.to || "/");
       this.apiLogin();
      })
      .catch((err) => {
       console.log(err);
       localStorage.removeItem("token");
       localStorage.removeItem("adminToken");
       localStorage.removeItem("avatar");
       localStorage.removeItem("account");
      });
    } else {
     this.errors.bool = true;
     this.errors.lists.push("allPasswordCondition");
    }
   }
  },
  async apiLogin() {
   const data = {
    email: this.form.email,
    password: this.form.password,
   };

   axios
    .post(`https://expert-stats-api.bluerocktel.net/api/login`, data)
    .then((res) => {
     localStorage.setItem("apiToken", res.data.token);
     this.$forceUpdate();
    })
    .then((res) => {})
    .catch((err) => {
     console.log(err);
     //window.alert("Connection refused. Please try again later.");
    });
  },
  async getPbxHosts(token, account) {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/pbxHosts`, {
     params: { customerAccount: account },
     headers: {
      Authorization: `Bearer ${token}`,
     },
    });
    if (res.data && res.data.length > 0) {
     this.$store.dispatch("hostNames", res.data);
     if (!this.hostName || this.hostName === "null" || this.hostName !== res.data[0]) {
      this.$store.dispatch("hostName", res.data[0]);
      localStorage.setItem("hostName", res.data[0]);
     } else {
      localStorage.setItem("hostName", this.hostName);
     }
    }
   } catch (error) {
    console.error(error);
   }
  },
  async apiLogin() {
   const data = {
    email: this.email,
    password: this.password,
   };

   axios
    .post(`https://expert-stats-api.bluerocktel.net/api/login`, data)
    .then((res) => {
     localStorage.setItem("apiToken", res.data.token);
     this.$forceUpdate();
    })
    .then((res) => {})
    .catch((err) => {
     console.log(err);
     //window.alert("Connection refused. Please try again later.");
    });
  },
  async getToken() {
   axios
    .get(`${this.$cookie.getCookie("API")}/api/v1/enterPassword/${this.passwordToken}`)
    .then((res) => {
     if (res) {
      this.checkToken = true;
     }
    })
    .finally(() => {
     this.$emit("setLoading", false);
    })
    .catch((err) => {
     console.error(err);
    });
  },
 },
 mounted() {
  this.getToken();
 },
 watch: {
  passwordToken: function (val) {
   this.getToken();
  },
 },
};
</script>

<style>
.login_page {
 background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
  url("../assets/paris_background.jpg") no-repeat center center fixed;
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 z-index: 0;
}
.login_card {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
}
#input-group-3__BV_label_ {
 margin-top: 0.2rem;
}
.d-block {
 font-weight: bold;
}
button {
 justify-items: auto;
 margin-inline-end: 0.5rem;
 margin-block-start: 0.5rem;
 margin-top: 0.6rem;
}

.card {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 max-width: 50rem;
 min-width: 30rem;
 /* border-radius: 10px; */
}
.card:hover {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.404);
}

.card-img-top img:hover {
 transform: scale(1.01);
}

.min-h-screen {
 min-height: 30vh;
}
.bg-gray-50 {
 background-color: rgba(255, 255, 255, 0.733);
}
.form_card {
 /* background-color: rgba(255, 255, 255, 0.733); */
 /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.404); */
 transition: 0.3s;
 padding: 0.1rem 10px 10px 10px;
 /* border-radius: 10px; */
}
.form_card #email-address {
 margin-top: -10px;
 margin-bottom: 10px;
}

.forgot_margin a {
 margin-left: 50px;
}

@media only screen and (max-width: 700px) {
 .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-width: 22rem;
  /* border-radius: 10px; */
 }
 .form_card {
  max-height: 100vh;
  max-width: 50vh;
 }
 .login_page {
  overflow-x: hidden;
  overflow-y: hidden;
 }
 .max-w-md {
  max-height: 100vh;
  max-width: fit-content;
 }
 .max-w-md img {
  max-height: 150px;
 }
}
</style>
